



























































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  onMounted,
  onBeforeUnmount
} from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    value: { type: Number, default: 0 },
    variant: {
      type: String as PropType<
        | 'primary'
        | 'neutral'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'danger'
        | 'white'
        | 'light'
      >,
      default: 'primary'
    },
    showProgress: { type: Boolean, default: false },
    progressPositionRight: { type: Boolean, default: false },
    linear: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    animated: { type: Boolean, default: false },
    progressClass: { type: [String, Array], default: null },
    barClasses: { type: [String, Array], default: null }
  },
  setup(props, { emit }) {
    const { variant, value } = toRefs(props)
    const dragging = ref(false)

    const pBarRef = ref<HTMLElement | null>(null)
    onMounted(() => {
      window.addEventListener('mouseup', stopDrag, false)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', stopDrag, false)
    })

    const variantBgColor = computed(() => {
      switch (variant.value) {
        case 'secondary': {
          return 'tw-bg-blue'
        }
        case 'neutral': {
          return 'tw-bg-grey-700'
        }
        case 'info': {
          return 'tw-bg-sky'
        }
        case 'success': {
          return 'tw-bg-green'
        }
        case 'warning': {
          return 'tw-bg-yellow'
        }
        case 'danger': {
          return 'tw-bg-red'
        }
        case 'white': {
          return 'tw-bg-white'
        }
        case 'light': {
          return 'tw-bg-grey-300'
        }
        case 'primary':
        default: {
          return 'tw-bg-primary'
        }
      }
    })
    const variantTextColor = computed(() => {
      if (variant.value === 'white') {
        return 'text-current'
      } else if (variant.value === 'light') {
        return 'tw-text-grey-700'
      }
      return 'tw-text-white'
    })

    function width() {
      if (value.value > 100) {
        return '100%'
      }
      return `${value.value}%`
    }

    const progressStyle = computed(() => {
      return {
        width: width()
      }
    })

    function handleProgressChange(e: Event | TouchEvent) {
      const rect = pBarRef.value.getBoundingClientRect()

      emit(
        'selected-progress',
        calcProgress(
          (e.targetTouches ? e.targetTouches[0].pageX : e.pageX) - rect.left,
          pBarRef.value.offsetWidth
        )
      )
    }

    function startDrag() {
      dragging.value = true
      window.addEventListener('mousemove', doDrag, false)
    }

    function doDrag(e: MouseEvent) {
      if (dragging.value) {
        const rect = pBarRef.value.getBoundingClientRect()
        emit(
          'selected-progress',
          calcProgress(e.pageX - rect.left, pBarRef.value.offsetWidth)
        )
      }
    }

    function stopDrag() {
      dragging.value = false
      window.removeEventListener('mousemove', doDrag)
    }

    function calcProgress(offset: number, offsetWidth: number) {
      const progress = (offset * 100) / offsetWidth
      if (progress > 100) {
        return 100
      } else if (progress < 0) {
        return 0
      }
      return progress
    }

    return {
      pBarRef,
      variantBgColor,
      variantTextColor,
      handleProgressChange,
      progressStyle,
      startDrag,
      doDrag
    }
  }
})
