var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-h-full tw-w-full tw-flex-col tw-relative tw-select-none tw-mx-auto",style:({ width: (_vm.width + "px"), height: (_vm.height + "px") })},[(_vm.stopVideoOutsideOfViewport)?[_c('div',{directives:[{name:"c-visible",rawName:"v-c-visible",value:({ handler: _vm.handleVisibleChange }),expression:"{ handler: handleVisibleChange }"}],staticClass:"tw-absolute tw-h-10 tw-top-1/2 tw--translate-y-1/2 tw-w-full"})]:_vm._e(),_vm._v(" "),(!_vm.controls || _vm.internalLoadVideoAfterClick)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVideoReady || _vm.internalLoadVideoAfterClick),expression:"isVideoReady || internalLoadVideoAfterClick"}],staticClass:"tw-absolute tw-w-full tw-flex tw-items-center tw-flex-col tw-cursor-pointer tw-h-full play-button-wrapper"},[(!_vm.isPlaying && !_vm.isLoading)?_c('button',{staticClass:"tw-z-10 tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-y-1/2 tw--translate-x-1/2",on:{"click":function($event){$event.stopPropagation();return _vm.playToggle($event)}}},[_c('fa',{staticClass:"tw-text-white tw-text-6xl",attrs:{"icon":_vm.faPlayCircle}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-h-full video-wrapper",class:{
      'tw-absolute': _vm.internalLoadVideoAfterClick && !_vm.isVideoReady,
      'tw-flex tw-flex-col tw-justify-center': _vm.center
    }},[_c('div',{staticClass:"tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-y-1/2 tw--translate-x-1/2"},[(_vm.isLoading && _vm.withoutThumb)?_c('CSpinner',{staticClass:"tw-z-10"}):_vm._e()],1),_vm._v(" "),(!_vm.internalLoadVideoAfterClick)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},[_c('video',{ref:"videoPlayerRef",staticClass:"video-js tw-h-full tw-w-full player",style:({
            width: _vm.width ? (_vm.width + "px") : undefined,
            height: _vm.height ? (_vm.height + "px") : undefined
          }),attrs:{"playsinline":""}})]),_vm._v(" "),(_vm.isProgressBarVisible)?_c('CProgressBar',{staticClass:"tw-relative tw-z-10 tw-cursor-pointer",attrs:{"linear":"","value":_vm.progress,"variant":"neutral"},on:{"selected-progress":_vm.setCurrentTime}}):_vm._e()]:_vm._e()],2),_vm._v(" "),(!_vm.withoutThumb && !_vm.isVideoReady && _vm.thumbnail)?_c('div',{staticClass:"tw-cursor-pointer tw-h-full tw-w-full",class:{
      'tw-absolute tw-top-0': !_vm.internalLoadVideoAfterClick && _vm.isLoading
    }},[_c('COverlay',{staticClass:"video-thumb-wrapper",attrs:{"show":_vm.isLoading}},[_c('CImg',{staticClass:"tw-w-full tw-h-full video-thumbnail tw-object-cover tw-min-w-24",attrs:{"src":_vm.thumbnail}})],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }